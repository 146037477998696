<template>
    <div class='card'>
        <h5 class='font-bold'>Parameter Models</h5>
    </div>
</template>

<script>
export default {
    name: 'Models',
};
</script>

<style scoped>

</style>